import { FetchClient } from "@/core/client/fetch_client"
import { StatusCode } from "@/core/generated/api/enums/status_code"
import { ResponseModel } from "@/core/generated/api/models/response_model"
import { ResponseSerializer } from "@/core/generated/api/response_serializer"
import { getService } from "hooks/useStartup"
import { useEffect, useState } from "react"
import { CVTemplate } from "../models/cvs/cv_template"

export function useCVTemplates() {
  const [data, setData] = useState<CVTemplate[]>([])
  const [loading, setLoading] = useState<boolean>(false)
  const [code, setCode] = useState<number>(StatusCode.Ok)
  const [message, setMessage] = useState<string>("")

  const client: FetchClient = getService(FetchClient)

  useEffect(() => {
    fetchCVTemplates()
  }, [])

  const fetchCVTemplates = async () => {
    setLoading(true)
    let response: ResponseModel | null = null

    try {
      response = await client.getAsync(`/flowcase/cvs/templates`)
      if (response !== null) {
        const model: CVTemplate[] =
          ResponseSerializer.deserialize<CVTemplate[]>(response)
        if (model !== null) {
          setData(model)
          setCode(response.code)
        } else {
          setCode(StatusCode.NoContent)
        }
      } else {
        setCode(StatusCode.BadRequest)
      }
    } catch (error) {
      console.error("Failed to fetch cv templates", error)
      setCode(StatusCode.BadRequest)
    } finally {
      setMessage(response?.message)
      setLoading(false)
    }
  }

  return { data, loading, code, message }
}
